<template>
    <section>
        <v-card class="pa-5 mb-5 custom-border border" v-for="(question, index) in questions" :key="question.id">
            <v-row no-gutters>
                <v-col cols="12" md="8" class="poppins f14 d-flex flex-column align-start">
                    <label class="fw500 secondary-2--text mr-2">
                        QUESTION:
                    </label>
                    <div v-html="question.question ? $dompurifier(question.question) : ''"></div>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                    <v-card-text class="poppins">
                        {{ question.hasOwnProperty('response_count') ? question.response_count : 0 }} responses
                    </v-card-text>
                </v-col> 
            </v-row>

            <GradingBarGraph
                v-if="!no_answers.includes(question.type_of_question) && !object_answer.includes(question.type_of_question)"
                :id="question.id"
                :graphLabel="question.type_of_question !== 'linear_scale' ? Object.values(question.data) : linearScaleLabel(Object.keys(question.data))"
                :graphData="getCountBar(question.data, question.id, index, question.type_of_question)"/>
            <GradingPieGraph 
                v-if="question.type_of_question === 'multiple_choice' || question.type_of_question === 'dropdown'"
                :id="question.id"
                :graphLabel="Object.values(question.data)"
                :graphData="getCountPie(question.data, question.id, index)"
            />
            <GradingBarGraphGrid 
                v-if="question.type_of_question === 'checkbox_grid'"
                :id="question.id"
                :data="getData(question.data, question.id, index, 'checkbox_grid')"
            />
            <GradingBarGraphGrid 
                v-if="question.type_of_question === 'multiple_choice_grid'"
                :id="question.id"
                :data="getData(question.data, question.id, index, 'multiple_choice_grid')"
            />
            <GradingAnswerWrapped v-if="question.type_of_question === 'date'" type="date" :answers="getAnswers(question.id, index)"/>
            <GradingAnswerWrapped v-if="question.type_of_question === 'time'" type="time" :answers="getAnswers(question.id, index)"/>
            <GradingAnswerWrapped v-if="question.type_of_question === 'short_answer'" type="short_answer" :answers="getAnswers(question.id, index)"/>
            <GradingAnswerWrapped v-if="question.type_of_question === 'paragraph'" type="paragraph" :answers="getAnswers(question.id, index)"/>
            <GradingAnswerWrapped v-if="question.type_of_question === 'file_upload'" type="link" :answers="getAnswers(question.id, index)"/>
        </v-card>
    </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import GradingBarGraph from '../grading/GradingBarGraph.vue';
import GradingBarGraphGrid from '../grading/GradingBarGraphGrid.vue';
import GradingPieGraph from '../grading/GradingPieGraph.vue';
import GradingAnswerWrapped from '../grading/GradingAnswerWrapped.vue';

export default {
    components: {
        GradingBarGraph,
        GradingPieGraph,
        GradingBarGraphGrid,
        GradingAnswerWrapped
    },
    data: () => ({
        responseCount: {},
        no_answers: ['file_upload', 'date', 'time', 'short_answer', 'paragraph', 'checkbox_grid', "multiple_choice_grid"],
        object_answer: ['multiple_choice', 'dropdown']
    }),
    computed: {
        ...mapState('instructor', {
            questions: (state) => state.questions,
            evaluation_answers: (state) => state.evaluation_answers
        }),
    },
    methods:{
        ...mapMutations('instructor', ['evaluationQuestionMutation']),
        getCountPie(choices, question_id, index) {
            let _choices = Object.keys(choices)
            let _data = []
            let _response_count = 0

            _choices.forEach( choice => {
                let _counter = 0
                this.evaluation_answers.forEach(answers => {

                    if(this.$route.params.type === 'course_evaluation'){
                        if(answers.answers.find(item => item.course_evaluation_question_id === question_id).data) {
                            if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                                if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer === choice) {
                                    _counter++
                                }
                            }
                        }
                    }
                    if(this.$route.params.type === 'module_evaluation'){
                        if(answers.answers.find(item => item.module_evaluation_question_id === question_id).data) {
                            if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                                if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer === choice) {
                                    _counter++
                                }
                            }
                        }
                    }
                    
                })
                _data.push(_counter)
            })

            this.evaluation_answers.forEach(answers => { 
                if(this.$route.params.type === 'course_evaluation'){
                    if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                        _response_count++
                    }
                }
                if(this.$route.params.type === 'module_evaluation'){
                    if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                        _response_count++
                    }
                }
            })

            this.questions[index].response_count = _response_count
            this.evaluationQuestionMutation(this.questions)
            return _data
        },
        getCountBar(choices, question_id, index, type) {
            let _choices = Object.keys(choices)
            let _data = []
            let _response_count = 0

            if(type !== 'linear_scale') {
                _choices.forEach( choice => {
                    let _counter = 0
                    this.evaluation_answers.forEach(answers => {
                        if(this.$route.params.type === 'course_evaluation'){
                            if(answers.answers.find(item => item.course_evaluation_question_id === question_id).data) {
                                if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                                    if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer.includes(choice)) {
                                        _counter++
                                    }
                                }
                            }
                        }
                        if(this.$route.params.type === 'module_evaluation'){
                            console.log(question_id)
                            console.log(answers.answers.find(item => item.module_evaluation_question_id === question_id))
                            if(answers.answers.find(item => item.module_evaluation_question_id === question_id).data) {
                                if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                                    if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer.includes(choice)) {
                                        _counter++
                                    }
                                }
                            }
                        }
                    })
                    _data.push(_counter)
                })
            } else {
                let labels = Array.from({ length: parseInt(_choices[1].split('_')[1]) }, (v, index) => (index) + 1)
                labels.forEach(i => {
                    let _counter = 0
                    this.evaluation_answers.forEach(answers => {
                        if(this.$route.params.type === 'course_evaluation'){
                            if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                                if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer === `scale_${i}`) {
                                    _counter++
                                }
                            }
                        }
                        if(this.$route.params.type === 'module_evaluation'){
                            if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                                if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer === `scale_${i}`) {
                                    _counter++
                                }
                            }
                        }
                    })
                    _data.push(_counter)
                })
            }

            this.evaluation_answers.forEach(answers => { 
                if(this.$route.params.type === 'course_evaluation'){
                    if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                        _response_count++
                    }
                }
                if(this.$route.params.type === 'module_evaluation'){
                    if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                        _response_count++
                    }
                }
            })

            console.log(_response_count)
            this.questions[index].response_count = _response_count
            this.evaluationQuestionMutation(this.questions)
            return _data
        },

        getAnswers(question_id, index) {
            let _data = []
            this.evaluation_answers.forEach(answers => { 
                if(this.$route.params.type === 'course_evaluation'){
                    if(answers.answers.find(item => item.course_evaluation_question_id === question_id).data) {
                        if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                            if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer) {
                                _data.push(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer)
                            }
                        }
                    }
                    
                    if(answers.answers.find(item => item.course_evaluation_question_id === question_id).image) {
                        _data.push(answers.answers.find(item => item.course_evaluation_question_id === question_id).image.url)
                    }
                }
                if(this.$route.params.type === 'module_evaluation'){
                    if(answers.answers.find(item => item.module_evaluation_question_id === question_id).data) {
                        if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                            if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer) {
                                _data.push(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer)
                            }
                        }
                    }

                    if(answers.answers.find(item => item.module_evaluation_question_id === question_id).image) {
                        _data.push(answers.answers.find(item => item.module_evaluation_question_id === question_id).image.url)
                    }
                }
            })
            
            this.questions[index].response_count = _data.length
            this.evaluationQuestionMutation(this.questions)

            return _data 
        },

        linearScaleLabel(data) {
            let labels = Array.from({ length: parseInt(data[1].split('_')[1]) }, (v, index) => (index) + 1)
            return labels
        },

        generateColor() {
            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);
            return `rgb(${red}, ${green}, ${blue})`;
        },

        getData(data, question_id, index, type) {
            let _data = {
                label: [],
                datasets: []
            }
            let _cols = []
            let _rows = []
            let _colors = []
            let _response_count = 0
            

            Object.keys(data).forEach(key => {
                if(key.substring(0, 3) === 'col') {
                    _data.label.push(data[key])
                }
                if(key.substring(0, 3) === 'col') {
                    _cols.push(key)
                }
                if(key.substring(0, 3) === 'row') {
                    _rows.push(key)
                }
            })

            _rows.forEach(_i => {
                let _randColor = this.generateColor()

                while(_colors.includes(_randColor)) {
                    _randColor = this.generateColor()
                }

                _colors.push(_randColor)

                let _dataset = {
                    label: data[_i],
                    backgroundColor: _randColor,
                    data: []
                }
                _cols.forEach(_j => {
                    let _counter = 0
                    
                    this.evaluation_answers.forEach(answers => {
                        if(this.$route.params.type === 'course_evaluation'){
                            if(type === 'checkbox_grid') {
                                if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                                    if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer.includes(`${_i}_${_j}`)) {
                                        _counter++
                                    }
                                }
                            } else if(type === 'multiple_choice_grid') {
                                if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                                    if(Object.values(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data).answer).includes(`${_i}_${_j}`)) {
                                        _counter++
                                    }
                                }
                            }
                        }
                        if(this.$route.params.type === 'module_evaluation'){
                            if(type === 'checkbox_grid') {
                                if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                                    if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer.includes(`${_i}_${_j}`)) {
                                        _counter++
                                    }
                                }
                            } else if(type === 'multiple_choice_grid') {
                                if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                                    if(Object.values(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data).answer).includes(`${_i}_${_j}`)) {
                                        _counter++
                                    }
                                }
                            }
                        }
                    })
                    _dataset.data.push(_counter)
                })
                _data.datasets.push(_dataset)
            })

            this.evaluation_answers.forEach(answers => { 
                if(this.$route.params.type === 'course_evaluation'){
                    if(JSON.parse(answers.answers.find(item => item.course_evaluation_question_id === question_id).data)) {
                        _response_count++
                    }
                }
                if(this.$route.params.type === 'module_evaluation'){
                    if(JSON.parse(answers.answers.find(item => item.module_evaluation_question_id === question_id).data)) {
                        _response_count++
                    }
                }
            })

            this.questions[index].response_count = _response_count
            this.evaluationQuestionMutation(this.questions)
            return _data
        }
    }
}
</script>
