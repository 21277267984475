<template>
    <section>
      <v-btn text class="poppins mt-3" color="primary" dense @click="$router.push({ name: 'Instructor Grading Course' , params: { id: $route.params.id }, query: { panel: 2}})">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
        Back
      </v-btn>
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            show-arrows
            color="primary"
            active-class="active"
          >
          <v-tab
              v-for="item in survey"
              :key="item"
              class="poppins text-capitalize f14 fw600"
              active-class="black--text"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn dense outlined color="primary" class="poppins float-right mt-3 mr-3" v-if="tab === 1" @click="exportSummary">
            EXPORT
          </v-btn>
        </v-col>
      </v-row>
      <v-tabs-items v-model="tab" style="background-color: transparent" class="py-2" v-if="!loading">
        <v-tab-item v-for="item in survey" :key="item" style="background-color: transparent">
            <SurveyIndividual v-if="item === 'Individual'" class="mx-2" />
            <SurveySummary v-if="item === 'Summary'" class="mx-3"/>
            <SurveyQuestion v-if="item === 'Question'" class="mx-3"/>
        </v-tab-item>
      </v-tabs-items>
      <circular v-else/>
      <ag-grid-vue
        style="width: 100%; height: 520px; visibility: hidden;"
        class="ag-theme-alpine ag-theme-mycustomtheme poppins"
        :gridOptions="{
          pagination: true,
          columnDefs: headers,
          rowData: users,
          defaultExcelExportParams: {...defaultExcelExportParams},
          excelStyles: [
              {
                  id: 'hyperlinks',
                  font: {
                      underline: 'Single',
                      color: '#358ccb'
                  }
              }
          ],
        }"
        @grid-ready="onGridReady"
        :modules="gridmodules">
    </ag-grid-vue>
    </section>
  </template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { mapActions, mapMutations, mapState } from 'vuex'
import survey from '../../constants/tabs/survey/survey';
import SurveySummary from '../../components/teacher/survey/SurveySummary.vue';
import SurveyQuestion from '../../components/teacher/survey/SurveyQuestion.vue';
import SurveyIndividual from '../../components/teacher/survey/SurveyIndividual.vue';

const TimeComponent = {
  template: '<input type="time" readonly :value="params.value"/>',
};

export default {
  components: {
    AgGridVue,
    SurveyIndividual,
    SurveyQuestion,
    SurveySummary,
    TimeComponent
  },
  data:() => ({
      survey,
      loading: false,
      gridmodules: [ExcelExportModule],
      headers: [{
        colId: '', 
        headerName: 'Name', 
        field: 'name', 
      }],
      users: [],
      gridApi: null,
      no_choices: ['file_upload', 'date', 'time', 'short_answer', 'paragraph'],
      defaultExcelExportParams: {},
  }),
  methods: {
    ...mapActions('instructor', ['showCourseEvaluationAction', 'showModuleEvaluationAction']),
    ...mapMutations('instructor', ['courseEvaluationsMutation', 'evaluationAnswersMutation', 'moduleEvaluationsMutation']),

    onGridReady(params) {
      this.gridApi = params.api;    
    },

    exportSummary(){
      this.gridApi.exportDataAsExcel();
    },

  },
  created(){
    
    this.defaultExcelExportParams = {
          autoConvertFormulas: true,
          processCellCallback: params => {
            const uuid = params.column.getColDef().field;
            if(uuid !== 'name') {
              if(this.questions[this.questions.findIndex(question => question.uuid === uuid)].type_of_question === 'file_upload') {
                return params.value ? `=HYPERLINK("${params.value}")` : ''
              } else {
                return params.value
              }
            } else return params.value
            
          }
      }
    if(this.$route.params.type === 'course_evaluation') {
      this.showCourseEvaluationAction(this.$route.params.survey_id).then(() => {
        this.questions.forEach(question => {
            if(question.type_of_question === 'file_upload') {
              this.headers.push({
                colId: question.id, 
                headerName: question.question ? question.question : '', 
                field: question.uuid, 
                cellClass: 'hyperlinks'
              })
            } else if(question.type_of_question === 'time') {
              this.headers.push({
                colId: question.id, 
                headerName: question.question ? question.question : '', 
                field: question.uuid, 
                cellRenderer: 'TimeComponent',
              })
            }  else {
              this.headers.push({
                colId: question.id, 
                headerName: question.question ? question.question : '', 
                field: question.uuid, 
              })
            }
            
          })

          this.gridApi.setColumnDefs(this.headers)
          this.gridApi.setRowData(this.users)
          this.gridApi.refreshHeader()
      })
    }
    if(this.$route.params.type === 'module_evaluation') {
      this.showModuleEvaluationAction(this.$route.params.survey_id).then(() => {
        this.questions.forEach(question => {
            if(question.type_of_question === 'file_upload') {
              this.headers.push({
                colId: question.id, 
                headerName: question.question ? question.question : '', 
                field: question.uuid, 
                cellClass: 'hyperlinks'
              })
            } else if(question.type_of_question === 'time') {
              this.headers.push({
                colId: question.id, 
                headerName: question.question ? question.question : '', 
                field: question.uuid, 
                cellRenderer: 'TimeComponent',
              })
            } else {
              this.headers.push({
                colId: question.id, 
                headerName: question.question ? question.question : '', 
                field: question.uuid, 
              })
            }
            
          })

          this.gridApi.setColumnDefs(this.headers)
          this.gridApi.setRowData(this.users)
          this.gridApi.refreshHeader()
      })
    }
    this.loading = true
    if(this.$route.params.type === 'course_evaluation') {
        let _evaluations = []
        this.$api.get(`/instructor/course/evaluation/${this.$route.params.survey_id}/respondents`).then(res => {
            this.courseEvaluationsMutation(res.data.data)
            let _users = res.data.data
            _users.forEach(_user => {
                if(_user.student_course_evaluations.length > 0) {
                    _evaluations.push(_user.student_course_evaluations[0])
                }
            })
            this.evaluationAnswersMutation(_evaluations)
            this.loading = false
        })
    } 
    else if(this.$route.params.type === 'module_evaluation') {
        let _evaluations = []
        this.$api.get(`/instructor/module/evaluation/${this.$route.params.survey_id}/respondents`).then(res => {
            this.moduleEvaluationsMutation(res.data.data)
            let _users = res.data.data
            _users.forEach(_user => {
                if(_user.student_module_evaluations.length > 0) {
                    _evaluations.push(_user.student_module_evaluations[0])
                }
            })
            this.evaluationAnswersMutation(_evaluations)

            this.loading = false
        })
    } 
  },
  mounted() {
    if(!this.$route.query || !this.$route.query.tab) {
      this.$router.replace({ query: { tab: 0, timestamp: Date.now() } });
    } else {
      this.$router.replace({ query: { tab: this.$route.query.tab, timestamp: Date.now() } });
    }
  },
  computed: {
    ...mapState('instructor', {
        questions: (state) => state.questions,
        evaluation_answers: (state) => state.evaluation_answers,
    }),
    tab: {
      get() {
        let tab = parseInt(this.$route.query.tab);
        return tab;
      },
      set(tab) {
        this.$router.replace({ query: { tab: tab, timestamp: Date.now() } });
      },
    },
  },
  watch:{
    evaluation_answers(val) {
      let _users = []
      val.forEach(_user => {
        let _questions = {}
        _user.answers.forEach(item => {
          let _choices = []
          let _question_type = ''
          let _uuid = null

          if(this.$route.params.type === 'course_evaluation') {
            _choices = item.course_evaluation_question.data
            _question_type = item.course_evaluation_question.type_of_question
            _uuid = item.course_evaluation_question.uuid
          } else if(this.$route.params.type === 'module_evaluation') {
            _choices = item.module_evaluation_question.data
            _question_type = item.module_evaluation_question.type_of_question
            _uuid = item.module_evaluation_question.uuid
          }

          if(_question_type === 'multiple_choice' || _question_type === 'dropdown') {
            if(JSON.parse(item.data)) {
              _questions[_uuid] = _choices[JSON.parse(item.data).answer] ? _choices[JSON.parse(item.data).answer] : ''
            }
          }
          if(this.no_choices.includes(_question_type)) {
              _questions[_uuid] = JSON.parse(item.data).answer
          }
          if(_question_type === 'linear_scale') {
              _questions[_uuid] = JSON.parse(item.data) ? JSON.parse(item.data).answer.replace('scale_', '') : ''
          }
          if(_question_type === 'checkbox') {
              let _answers = JSON.parse(item.data).answer
              let _answer = []
              if(_answers) {
                _answers.forEach(_i =>{
                  _answer.push(_choices[_i])
                })
              }
              _questions[_uuid] = _answer.join(', ')
          }
          if(_question_type === 'multiple_choice_grid') {
            if(JSON.parse(item.data)) {
              let _answer = []
              let _answers = Object.values(JSON.parse(item.data).answer)
              _answers.forEach(i => {
                let _row = i.substring(0,5)
                let _col = i.substring(6)
                _answer.push(`${_choices[_row]} - ${_choices[_col]}`)
              })
              _questions[_uuid] = _answer.join(', ') 
            }
          }
          if(_question_type === 'checkbox_grid') {
            if(JSON.parse(item.data)) {
              let _answer = []
              JSON.parse(item.data).answer.forEach(i => {
                let _row = i.substring(0,5)
                let _col = i.substring(6)
                _answer.push(`${_choices[_row]} - ${_choices[_col]}`)
              })
              _questions[_uuid] = _answer.join(', ') 
            }
          }
          if(_question_type === 'file_upload') {
            if(item.image) {
              _questions[_uuid] = item.image.url
            }
          }

        })
        _users.push({
          name: _user.user_id,
          ..._questions
        })
      })

      this.users = _users

      this.gridApi.setColumnDefs(this.headers)
      this.gridApi.setRowData(this.users)
      this.gridApi.refreshHeader()
    }
  }
}

</script>
