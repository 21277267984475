<template>
    <section>
        <!-- <SurveyQuestionHeader /> -->
        <v-card class="d-flex flex-row align-center pa-3 mb-5 custom-border border">
            <v-btn icon @click="prevQuestion">
                <v-icon color="primary">
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <v-select 
                hide-details
                dense
                :items="questions"
                item-value="id"
                v-model="selected">
                <template slot="item" slot-scope="data">
                    <v-list-item-content>
                        <v-list-item-title class="roboto f14 fw500">
                            <span class="secondary-3--text inner_p" v-html="data.item.question ? $dompurifier(data.item.question) : ''"></span>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <template slot="selection"  slot-scope="data">
                    <span class="f12 secondary-2--text fw500">QUESTION: &nbsp;</span><span class="roboto f14 fw500 inner_p" v-html="data.item.question ? $dompurifier(data.item.question) : ''">
                    </span>
                </template>
            </v-select>
            <v-btn icon @click="nextQuestion">
                <v-icon color="primary">
                    mdi-chevron-right
                </v-icon>
            </v-btn>
        </v-card>
        <v-card class="py-5 custom-border border" v-if="selected">
            <section v-if="getTypeOfQuestion === 'multiple_choice' || getTypeOfQuestion === 'checkbox' || getTypeOfQuestion === 'dropdown'">
                <section class="pa-2" v-for="(key, index) in Object.keys(getData)" :key="index">
                    <v-sheet color="secondary" class="rounded mx-5 pa-2">
                        {{ getData[key] }}
                    </v-sheet>
                    <SurveyQuestionRespondentMenu :responses_count="getNumberOfResponses(key, index)" :respondents="respondents[index]"/>
                </section>
            </section>
            <section v-if="getTypeOfQuestion === 'linear_scale'">
                <section class="pa-2" v-for="(n, index) in parseInt(Object.keys(getData)[1].split('_')[1])">
                    <v-sheet color="secondary" class="rounded mx-5 pa-2">
                        {{ n }}
                    </v-sheet>
                    <SurveyQuestionRespondentMenu :responses_count="getNumberOfResponses(key, index)" :respondents="respondents[index]"/>
                </section>
            </section>
            <section v-if="getTypeOfQuestion === 'date' || getTypeOfQuestion === 'time' || getTypeOfQuestion === 'short_answer' || getTypeOfQuestion === 'paragraph'" >
                <section class="pa-2" v-for="(key, index) in getAnswers" :key="index">
                    <v-sheet v-if="getTypeOfQuestion === 'date'" color="secondary" class="rounded mx-5 pa-2">
                        {{ $dateFormat.mmDDyy(key) }}
                    </v-sheet>
                    <v-sheet v-else-if="getTypeOfQuestion === 'time'" color="secondary" class="rounded mx-5 pa-2">
                        <input type="time" readonly :value="key"/>
                    </v-sheet>
                    <v-sheet v-else color="secondary" class="rounded mx-5 pa-2">
                        {{ key }}
                    </v-sheet>
                    <SurveyQuestionRespondentMenu :responses_count="getNumberOfResponsesUnique(key, index)" :respondents="respondents[index]"/>
                </section>
            </section>
            <section v-if="getTypeOfQuestion === 'file_upload'" >
                <section class="pa-2" v-for="(key, index) in getAnswersFile" :key="index">
                    <v-sheet color="secondary" class="rounded mx-5 pa-2">
                        <a :href="key" class="secondary-1--text" target="_blank">{{ key }}</a>
                    </v-sheet>
                </section>
            </section>
            <section v-if="getTypeOfQuestion === 'multiple_choice_grid' || getTypeOfQuestion === 'checkbox_grid'" >
                <section class="pa-2" v-for="(key, index) in getAnswersGrid" :key="index">
                    <v-sheet color="secondary" class="rounded mx-5 pa-2">
                        {{  key }}
                    </v-sheet>
                    <SurveyQuestionRespondentMenu :responses_count="getNumberOfResponsesUnique(key, index)" :respondents="respondents[index]"/>
                </section>
            </section>
        </v-card>
    </section>
</template>

<style>
.inner_p > p {
    margin-bottom: 0 !important;
}
</style>

<script>
import { mapState } from 'vuex'
import SurveyQuestionRespondentMenu from './SurveyQuestionRespondentMenu.vue'
// import SurveyQuestionHeader from './SurveyQuestionHeader.vue';

export default {
    data: () => ({
        selected: null,
        object_answer: ['multiple_choice', 'dropdown'],
        no_answers: ['file_upload', 'date', 'time', 'short_answer', 'paragraph'],
        respondents: []
    }),
    components: {
        SurveyQuestionRespondentMenu
        // SurveyQuestionHeader
    },
    mounted() {
        if(this.questions) {
            this.selected = this.questions[0].id
        }
    },
    computed: {
        ...mapState('instructor', {
            questions: (state) => state.questions,
            evaluation_answers: (state) => state.evaluation_answers,
            course_evaluations: (state) => state.course_evaluations,
            module_evaluations: (state) => state.module_evaluations
        }),
        getTypeOfQuestion(){
            if(this.selected) {
                return this.questions.find( item => item.id === this.selected).type_of_question
            } else {
                return ''
            }
        },
        getData() {
            if(this.selected) {
                return this.questions.find( item => item.id === this.selected).data
            } else {
                return ''
            }
        },
        getAnswers() {
            if(this.selected) {
                let _arr = []
                this.evaluation_answers.forEach( answer => {
                    let _answer = null

                    if(this.$route.params.type === 'course_evaluation'){
                        _answer = answer.answers[answer.answers.findIndex( item => item.course_evaluation_question_id === this.selected)]
                    }
                    if(this.$route.params.type === 'module_evaluation'){
                        _answer = answer.answers[answer.answers.findIndex( item => item.module_evaluation_question_id === this.selected)]
                    }
                    
                    if(!_arr.includes(JSON.parse(_answer.data).answer)) {
                        _arr.push(JSON.parse(_answer.data).answer)
                    }
                })

                return _arr
            } else {
                return []
            }
        },
        getAnswersFile() {
            if(this.selected) {
                let _arr = []
                this.evaluation_answers.forEach( answer => {
                    let _answer = null
                    if(this.$route.params.type === 'course_evaluation'){
                        _answer = answer.answers[answer.answers.findIndex( item => item.course_evaluation_question_id === this.selected)]
                    }
                    if(this.$route.params.type === 'module_evaluation'){
                        _answer = answer.answers[answer.answers.findIndex( item => item.module_evaluation_question_id === this.selected)]
                    }

                    console.log(_answer)
                    if(_answer.image) {
                        _arr.push(_answer.image.url)
                    }
                })

                return _arr
            } else {
                return []
            }
        },
        getAnswersGrid() {
            if(this.selected) {
                let _arr = []
                this.evaluation_answers.forEach( answer => {
                    let _answer = null
                    let getChoices = null
                    
                    if(this.$route.params.type === 'course_evaluation'){
                        _answer = answer.answers[answer.answers.findIndex( item => item.course_evaluation_question_id === this.selected)]
                        getChoices = _answer.course_evaluation_question.data
                    }

                    if(this.$route.params.type === 'module_evaluation'){
                        _answer = answer.answers[answer.answers.findIndex( item => item.module_evaluation_question_id === this.selected)]
                        getChoices = _answer.module_evaluation_question.data
                    }
                        
                    Object.values(JSON.parse(_answer.data).answer).forEach(i => {
                        let _row = i.substring(0,5)
                        let _col = i.substring(6)

                        let _ans = `${getChoices[_row]} - ${getChoices[_col]}`
                        if(!_arr.includes(_ans)) {
                            _arr.push(_ans)
                        }
                    })
                })

                return _arr
            } else {
                return []
            }
        }
    },
    methods: {
        nextQuestion() {
            if(this.questions.findIndex( item => item.id === this.selected) === (this.questions.length -1)) {
                this.selected = this.questions[0].id
            } else {
                this.selected = this.questions[this.questions.findIndex( item => item.id === this.selected) + 1].id
            }
        },
        prevQuestion() {
            if(this.questions.findIndex( item => item.id === this.selected) === 0) {
                this.selected = this.questions.length -1
            } else {
                this.selected = this.questions[this.questions.findIndex( item => item.id === this.selected) - 1].id
            }
        },
        getNumberOfResponses(answer_key, index) {
            let _count = 0
            let _respondents = []

            this.evaluation_answers.forEach( answer => {
                let _answer = null

                if(this.$route.params.type === 'course_evaluation') {
                    _answer = answer.answers[answer.answers.findIndex( item => item.course_evaluation_question_id === this.selected)]
                }
                if(this.$route.params.type === 'module_evaluation') {
                    _answer = answer.answers[answer.answers.findIndex( item => item.module_evaluation_question_id === this.selected)]
                }
                    
                if(this.object_answer.includes(this.questions[this.questions.findIndex( item => item.id === this.selected)].type_of_question)) {
                    if(JSON.parse(_answer.data)){
                        if(JSON.parse(_answer.data).answer === answer_key) {
                            _count++

                            let _user = null

                            if(this.$route.params.type === 'course_evaluation') {
                                _user = this.course_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            if(this.$route.params.type === 'module_evaluation') {
                                _user = this.module_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            
                            _respondents.push(`${_user.first_name ? _user.first_name : 'User'} ${_user.last_name ? _user.last_name : 'Account'}`)
                        }
                    }
                } else if(this.questions[this.questions.findIndex( item => item.id === this.selected)].type_of_question === 'linear_scale') {
                    if(JSON.parse(_answer.data)){
                        if(JSON.parse(_answer.data).answer === `scale_${answer_key}`) {
                            _count++

                            let _user = null
                            if(this.$route.params.type === 'course_evaluation') {
                                _user = this.course_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            if(this.$route.params.type === 'module_evaluation') {
                                _user = this.module_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            _respondents.push(`${_user.first_name ? _user.first_name : 'User'} ${_user.last_name ? _user.last_name : 'Account'}`)
                        }
                    }
                } else if (!this.no_answers.includes(this.questions[this.questions.findIndex( item => item.id === this.selected)].type_of_question)){
                    if(JSON.parse(_answer.data)){
                        if(JSON.parse(_answer.data).answer.includes(answer_key)) {
                            _count++

                            let _user = null
                            if(this.$route.params.type === 'course_evaluation') {
                                _user = this.course_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            if(this.$route.params.type === 'module_evaluation') {
                                _user = this.module_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            _respondents.push(`${_user.first_name ? _user.first_name : 'User'} ${_user.last_name ? _user.last_name : 'Account'}`)

                        }
                    }
                }
                
                this.respondents[index] = _respondents
            })

            return _count
        },

        getNumberOfResponsesUnique(answer_key, index) {
            let _count = 0
            let _respondents = []

            this.evaluation_answers.forEach( answer => {
                if(this.questions[this.questions.findIndex( item => item.id === this.selected)].type_of_question === 'multiple_choice_grid' || this.questions[this.questions.findIndex( item => item.id === this.selected)].type_of_question === 'checkbox_grid') {
                    let _answer = null
                    let getChoices = null

                    if(this.$route.params.type === 'course_evaluation') {
                        _answer = answer.answers[answer.answers.findIndex( item => item.course_evaluation_question_id === this.selected)]
                        getChoices = _answer.course_evaluation_question.data
                    }
                    if(this.$route.params.type === 'module_evaluation') {
                        _answer = answer.answers[answer.answers.findIndex( item => item.module_evaluation_question_id === this.selected)] 
                        getChoices = _answer.module_evaluation_question.data
                    }

                    Object.values(JSON.parse(_answer.data).answer).forEach(i => {
                        let _row = i.substring(0,5)
                        let _col = i.substring(6)

                        if(`${getChoices[_row]} - ${getChoices[_col]}` === answer_key) {
                            let _user = null
                            if(this.$route.params.type === 'course_evaluation') {
                                _user = this.course_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            if(this.$route.params.type === 'module_evaluation') {
                                _user = this.module_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            _respondents.push(`${_user.first_name ? _user.first_name : 'User'} ${_user.last_name ? _user.last_name : 'Account'}`)
                            _count++
                        }

                        this.respondents[index] = _respondents
                    })

                } else {
                    let _answer = answer.answers[answer.answers.findIndex( item => item.course_evaluation_question_id === this.selected)]
                    if(JSON.parse(_answer.data)) {
                        if(JSON.parse(_answer.data).answer === answer_key) {
                            _count++
                            let _user = null
                            if(this.$route.params.type === 'course_evaluation') {
                                _user = this.course_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            if(this.$route.params.type === 'module_evaluation') {
                                _user = this.module_evaluations.find(_user =>
                                    _user.id === answer.user_id
                                ) 
                            }
                            _respondents.push(`${_user.first_name ? _user.first_name : 'User'} ${_user.last_name ? _user.last_name : 'Account'}`)
                        }
                    }
                    this.respondents[index] = _respondents
                }
            })

            return _count
        }
    }
}
</script>
