<template>
  <section>
    <v-text-field
      solo
      dense
      flat
      class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500 mt-3"
      hide-details
      prepend-inner-icon="mdi-magnify"
      placeholder="Search student name"
      v-model="search"
    />
    <v-data-table
      v-if="!loading"
      :search="search"
      :headers="grading_survey_tbl"
      :items="$route.params.type === 'course_evaluation' ? course_evaluations : module_evaluations"
      class="poppins custom-border">
          <template v-slot:item.status="{ item }">
              <v-alert v-if="item.status !== -1" dense :color="item.status ? '#7BC14533' : '#BDBDBD33'" class="ma-auto" :class="item.status ? 'success--text' : 'secondary-2--text'">
                  {{ item.status ? 'CHECKED' : 'PENDING' }}
              </v-alert>
          </template>
          <template v-slot:item.date_submitted="{ item }">
            <span>
              {{ item.date_submitted !== null ? $dateFormat.mmDDyy(item.date_submitted) : ''}}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn text class="text-capitalize primary--text f11 fw600" :to="{ name: 'Instructor Survey Student', params: { type: $route.params.type,survey_id: $route.params.survey_id, student_id: item.id}}">
              <v-icon left>mdi-eye</v-icon>
              View
            </v-btn>
          </template>
      </v-data-table>
      <circular v-else/>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { grading_survey_tbl } from '../../../constants/tblheaders/grading';
export default {
    mounted() {
        // this.loading = true
        // if(this.$route.params.type === 'course_evaluation') {
        //     let _evaluations = []
        //     this.$api.get(`/instructor/course/evaluation/${this.$route.params.survey_id}/respondents`).then(res => {
        //         this.users = res.data.data
        //         this.users.forEach(_user => {
        //             if(_user.student_course_evaluations.length > 0) {
        //                 _evaluations.push(_user.student_course_evaluations[0])
        //             }
        //         })
        //         this.evaluationAnswersMutation(_evaluations)
        //         this.loading = false
        //     })
        // } 
    },
    methods: {
        ...mapMutations('instructor',  ['evaluationAnswersMutation'])
    },
    data: () => ({
      grading_survey_tbl,
      users: [],
      loading: false,
      search: ''
    }),
    computed: {
        ...mapState('instructor', {
            course_evaluations: (state) => {
              let users = []
              state.course_evaluations.forEach(user => {
                let _user = {}
                _user.id = user.id
                _user.user = `${ user.first_name ? user.first_name : 'User' } ${ user.last_name ? user.last_name : 'Account' }`
                _user.attempts = user.student_course_evaluations_count
                _user.date_submitted = user.student_course_evaluations.length > 0 ? user.student_course_evaluations[0].created_at : null
                _user.status = user.student_course_evaluations.length > 0 ? user.student_course_evaluations[0].is_checked : -1
                users.push(_user)
              })

              return users
            },
            module_evaluations: (state) => {
              let users = []
              state.module_evaluations.forEach(user => {
                let _user = {}

                _user.id = user.id
                _user.user = `${ user.first_name ? user.first_name : 'User' } ${ user.last_name ? user.last_name : 'Account' }`
                _user.attempts = user.student_module_evaluations_count
                _user.date_submitted = user.student_module_evaluations.length > 0 ? user.student_module_evaluations[0].created_at : null
                _user.status = user.student_module_evaluations.length > 0 ? user.student_module_evaluations[0].is_checked : -1

                users.push(_user)
              })

              return users
            },
        })
    }
}
</script>
